import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import SuccessImg from '../assets/success_icon.png';
import styled from 'styled-components';
import OpenWebcamButton from './WebcamButton';
import CameraUtils from '../utils/CameraUtils';
import UnicoSDKCamera from './UnicoSDKCamera';
import UnicoSDKService from '../services/unico/UnicoSdkService';

interface TakePhotoOrganismProps {
  imageErrorExplanation: string;

  isHandlingPhoto: boolean;
  isPhotoValid: boolean;
  useFrontalCamera: boolean;
  takenImage?: string;
  cameraSDK: UnicoSDKService;

  onPhoto: (
    base64Photo: string,
    photoVersion?: string,
    logObject?: any
  ) => Promise<void>;
}

const TakePhotoOrganism = ({
  isHandlingPhoto,
  imageErrorExplanation,
  isPhotoValid,
  useFrontalCamera,
  onPhoto,
  takenImage,
  cameraSDK,
}: TakePhotoOrganismProps) => {
  const [isTakingPhoto, setIsTakingPhoto] = useState<boolean>(!!!takenImage);

  const handleTakenDesktopPhoto = async (imageBase64: string) => {
    setIsTakingPhoto(false);
    await onPhoto(imageBase64);
  };

  const handleRetryPhoto = () => {
    setIsTakingPhoto(true);
  };

  if (isPhotoValid) {
    return (
      <>
        <ImageContainer>
          <Image src={takenImage} />
        </ImageContainer>
        <SuccessfulContainer>
          <SuccessfulText>Foto submetida com sucesso</SuccessfulText>
          <SuccessImage src={SuccessImg} />
        </SuccessfulContainer>
      </>
    );
  }

  if (!CameraUtils.shouldUseWebcam()) {
    if (isTakingPhoto && !isHandlingPhoto) {
      return (
        <UnicoSDKCamera
          onPhoto={handleTakenDesktopPhoto}
          sdkCameraInstance={cameraSDK}
        />
      );
    }

    return (
      <>
        <ImageContainer>
          {isHandlingPhoto ? <CircularProgress /> : <Image src={takenImage} />}
        </ImageContainer>
        <ErrorExplanation>{imageErrorExplanation}</ErrorExplanation>
        <CustomButton disabled={isHandlingPhoto} onClick={handleRetryPhoto}>
          Tente novamente
        </CustomButton>
      </>
    );
  } else {
    if (isTakingPhoto && !isHandlingPhoto) {
      return (
        <OpenWebcamButton
          onPhoto={handleTakenDesktopPhoto}
          useFrontalCamera={useFrontalCamera}
        >
          Tirar foto
        </OpenWebcamButton>
      );
    }

    return (
      <>
        <ImageContainer>
          {isHandlingPhoto ? <CircularProgress /> : <Image src={takenImage} />}
        </ImageContainer>
        <ErrorExplanation>{imageErrorExplanation}</ErrorExplanation>
        <CustomButton disabled={isHandlingPhoto} onClick={handleRetryPhoto}>
          Tente novamente
        </CustomButton>
      </>
    );
  }
};

export default TakePhotoOrganism;

const ImageContainer = styled.div<{ disabled?: boolean }>`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 70%;
  ${(props: any) => (props && props.disabled ? 'display: none' : '')};
`;

const EmptyCameraImage = styled.img`
  width: 100%;
`;

const Image = styled.img`
  // height: auto;
  // width: 100%;
  max-height: 80%;

  max-width: 200px;
`;

const ErrorExplanation = styled.span`
  text-align: center;
  color: red;
`;

const SuccessfulContainer = styled.div`
  text-align: center;
`;

const SuccessImage = styled.img`
  margin-left: 3vw;
`;

const SuccessfulText = styled.span`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.15px;

  /* Color/Dark/300 */

  color: #7a7e7a;
`;

const CustomButton = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  background-color: #11b048 !important;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
  border: 0px;
  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')};
`;
