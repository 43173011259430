import { MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import ApplicationLayout from '../layout/ApplicationLayout';
import EmptyCameraImg from '../assets/empty_camera.png';
import DocumentPhotoCard from '../components/DocumentPhotoCard';
import DocumentCameraPage from './DocumentCameraPage';
import ApplicationDocumentsUtils, {
  AllowedDocuments,
  DocumentPhotoDescription,
  DocumentViewAngle,
} from '../utils/ApplicationDocumentsUtils';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import applicationReducer from '../redux/reducers/application.reducer';
import { debounce } from 'lodash';
const NO_DOCUMENT_SELECTED = 'NO_DOCUMENT_SELECTED';

const DocumentsPage = () => {
  const dispatch = useAppDispatch();

  const {
    successfulDocumentPhotos,
    hasTakenAllRequiredDocumentPhotos,
    currentChosenDocument,
    referralCode,
    makingRequest,
  } = useAppSelector((state) => ({
    successfulDocumentPhotos: state.application.successfulDocumentPhotos,
    hasTakenAllRequiredDocumentPhotos:
      state.application.hasTakenAllRequiredDocumentPhotos,
    currentChosenDocument: state.application.currentChosenDocument,
    referralCode: state.application.referralCode,
    makingRequest: state.application.makingRequest,
  }));

  const [formDocument, setFormDocument] = useState<AllowedDocuments>(
    currentChosenDocument ?? (NO_DOCUMENT_SELECTED as AllowedDocuments)
  );

  const [isTakingPhoto, setIsTakingPhoto] = useState<boolean>(false);
  const [takingDocumentPhotoDescription, setTakingDocumentPhotoDescription] =
    useState<DocumentPhotoDescription>();

  const handleChange = (event: SelectChangeEvent<AllowedDocuments>, child?: React.ReactNode) => {
    setFormDocument(event.target.value as AllowedDocuments);
  };
  


  const handleTakePhotoRequest = (
    photoDocumentPerspective: DocumentPhotoDescription
  ) => {
    setTakingDocumentPhotoDescription({
      document: formDocument as AllowedDocuments,
      documentViewAngle: photoDocumentPerspective.documentViewAngle,
      documentViewAngleType: photoDocumentPerspective.documentViewAngleType,
    });
    setIsTakingPhoto(true);
  };
  const handleCloseCameraPage = () => {
    setIsTakingPhoto(false);
  };

  const handleSuccessfulDocumentPhoto = (
    documentType: DocumentPhotoDescription,
    imageBase64: string
  ) => {
    setIsTakingPhoto(false);
    dispatch(
      applicationReducer.actions.onSuccessfulDocumentPhoto({
        imageBase64,
        documentType,
        document: formDocument,
      })
    );
  };

  const onNextPageClicked = async (defaultBehavior: Function) => {
    dispatch(applicationReducer.actions.submitApplication(referralCode));
    // dispatch(applicationReducer.actions.nextPage());
  };

  const hasSelectedADocument =
    formDocument !== (NO_DOCUMENT_SELECTED as AllowedDocuments);

  if (isTakingPhoto) {
    return (
      <DocumentCameraPage
        onGoBack={handleCloseCameraPage}
        documentPhotoDescription={takingDocumentPhotoDescription}
        onSuccessfulDocumentPhoto={handleSuccessfulDocumentPhoto}
      />
    );
  }

  return (
    <ApplicationLayout
      title={'Vamos precisar de um documento'}
      subtitle={
        'Por favor nos envie as fotos frente e verso de um documento válido.'
      }
      isNextButtonEnabled={hasTakenAllRequiredDocumentPhotos && !makingRequest}
      overrideNextPage={debounce(onNextPageClicked, 500)}
    >
      <Select value={formDocument} onChange={handleChange}>
        <MenuItem value={NO_DOCUMENT_SELECTED}>
          <em>Selecionar</em>
        </MenuItem>
        {Object.keys(
          ApplicationDocumentsUtils.DOCUMENT_LABEL_TO_UI_TEXT_MAP
        ).map((key, index) => (
          <MenuItem value={key} key={index}>
            {ApplicationDocumentsUtils.DOCUMENT_LABEL_TO_UI_TEXT_MAP[key]}
          </MenuItem>
        ))}
      </Select>

      <DocumentsImageContainer isVisible={hasSelectedADocument}>
        <DocumentPhotoCard
          title={`Foto da frente: ${ApplicationDocumentsUtils.DOCUMENT_LABEL_TO_UI_TEXT_MAP[formDocument]}`}
          defaultImg={EmptyCameraImg}
          validImg={successfulDocumentPhotos[formDocument]?.FRONT}
          onTakePhotoRequest={() =>
            handleTakePhotoRequest({
              document: formDocument,
              documentViewAngle: DocumentViewAngle.FRONT,
              documentViewAngleType:
                ApplicationDocumentsUtils.ALLOWED_DOCUMENTS[formDocument].front,
            })
          }
        />
        <DocumentPhotoCard
          title={`Foto do verso: ${ApplicationDocumentsUtils.DOCUMENT_LABEL_TO_UI_TEXT_MAP[formDocument]}`}
          defaultImg={EmptyCameraImg}
          validImg={successfulDocumentPhotos[formDocument]?.BACK}
          onTakePhotoRequest={() =>
            handleTakePhotoRequest({
              document: formDocument,
              documentViewAngle: DocumentViewAngle.BACK,
              documentViewAngleType:
                ApplicationDocumentsUtils.ALLOWED_DOCUMENTS[formDocument].back,
            })
          }
        />
      </DocumentsImageContainer>
    </ApplicationLayout>
  );
};

export default DocumentsPage;

const DocumentsImageContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;

  visibility: ${(props: any) => (props.isVisible ? 'visible' : 'hidden')};
`;
