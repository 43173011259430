import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationStatus } from '../../services/bff/BffService';
import { ZipCodeAddress } from '../../services/zipcode/ZipcodeSearchService';
import { ApplicationProfession } from '../../utils/application-professions';
import {
  AllowedDocuments,
  DocumentPhotoDescription,
  DocumentViewAngle,
} from '../../utils/ApplicationDocumentsUtils';
import FirebaseService from '../../services/firebase/FirebaseService';

export enum ApplicationFormAttributes {
  CPF = 'cpf',
  FULL_NAME = 'fullName',
  BIRTH_DATE = 'birthDate',
  PROFESSION = 'profession',
  EMAIL = 'email',
  ZIP_CODE = 'zipCode',
  ENRICHED_ZIP_CODE = 'enrichedZipCode',
  ADDRESS_NUMBER = 'addressNumber',
  ADDITIONAL_ADDRESS = 'additionalAddress',
  PHONE_NUMBER = 'phoneNumber',
}

export enum ApplicationPages {
  ONBOARDING = 'ONBOARDING',
  LOADING_PAGE = 'LOADING_PAGE',
  CPF_PAGE = 'CPF_PAGE',
  PHONE_PAGE = 'PHONE_PAGE',
  BORROWER_DETAILS_PAGE = 'BORROWER_DETAILS_PAGE',
  ZIP_CODE_PAGE = 'ZIP_CODE_PAGE',
  BIOMETRY_PAGE = 'BIOMETRY_PAGE',
  DOCUMENTS_PAGE = 'DOCUMENTS_PAGE',
  WAITING_MANUAL_APPROVAL_PAGE = 'WAITING_MANUAL_APPROVAL_PAGE',
  DENIED_PAGE = 'DENIED_PAGE',
  APPLICATION_ALREADY_EXISTS_PAGE = 'APPLICATION_ALREADY_EXISTS_PAGE',
  APPLICATION_APPROVED_PAGE = 'APPLICATION_APPROVED_PAGE',
}

interface SuccessfulDocumentPhotos {
  [key: string]: {
    [DocumentViewAngle.FRONT]: string;
    [DocumentViewAngle.BACK]: string;
  };
}

interface ApplicationReducerState {
  currentPage: ApplicationPages;
  currentPageIndex: number;
  pagesOrder: ApplicationPages[];
  referralCode: string;

  cpf?: string;
  fullName?: string;
  birthDate?: string;
  profession?: ApplicationProfession;
  email?: string;

  zipCode: string;
  addressNumber: string;
  additionalAddress: string;
  enrichedZipCode?: ZipCodeAddress;

  phoneNumber: string;
  isPhoneVerified: boolean;
  wasPhoneSubmitted?: boolean;

  successfulBiometryImageBase64?: string;
  successfulDocumentPhotos: SuccessfulDocumentPhotos;
  hasTakenAllRequiredDocumentPhotos: boolean;
  currentChosenDocument?: AllowedDocuments;

  isEvaluatingApplication: boolean;
  makingRequest: boolean;
  creditLimit?: number;
}

const initialState: ApplicationReducerState = {
  pagesOrder: [
    ApplicationPages.ONBOARDING,
    ApplicationPages.CPF_PAGE,
    ApplicationPages.BORROWER_DETAILS_PAGE,
    ApplicationPages.ZIP_CODE_PAGE,
    ApplicationPages.PHONE_PAGE,
    ApplicationPages.BIOMETRY_PAGE,
    ApplicationPages.DOCUMENTS_PAGE,
  ],
  // pagesOrder: [ApplicationPages.CPF_PAGE, ApplicationPages.APPLICATION_ALREADY_EXISTS_PAGE, ApplicationPages.DOCUMENTS_PAGE],
  currentPage: ApplicationPages.ONBOARDING,
  currentPageIndex: 0,
  referralCode: '',

  cpf: undefined,
  fullName: '',
  birthDate: '',
  profession: undefined,
  email: '',
  zipCode: '',

  addressNumber: '',
  additionalAddress: '',

  phoneNumber: '',
  isPhoneVerified: false,
  wasPhoneSubmitted: false,

  successfulBiometryImageBase64: undefined,
  successfulDocumentPhotos: {},
  hasTakenAllRequiredDocumentPhotos: false,

  isEvaluatingApplication: false,
  makingRequest: false,
  creditLimit: undefined,
};

export const applicationReducer = createSlice({
  name: 'application',
  initialState,
  reducers: {
    onFormChange: (state, action: PayloadAction<OnFormChangeAction>) => {
      return { ...state, [action.payload.type]: action.payload.value };
    },
    onMultipleFormChange: (
      state,
      action: PayloadAction<OnFormChangeAction[]>
    ) => {
      let changedValues = {};
      for (const changedValue of action.payload) {
        changedValues = {
          ...changedValues,
          [changedValue.type]: changedValue.value,
        };
      }
      return { ...state, ...changedValues };
    },
    onApplicationAlreadyExists: (state) => {
      FirebaseService.logScreenView(
        ApplicationPages.APPLICATION_ALREADY_EXISTS_PAGE
      );
      return {
        ...state,
        pagesOrder: [ApplicationPages.APPLICATION_ALREADY_EXISTS_PAGE],
        currentPageIndex: 0,
        currentPage: ApplicationPages.APPLICATION_ALREADY_EXISTS_PAGE,
      };
    },
    onApplicationWaitingManualApproval: (state) => {
      return {
        ...state,
        pagesOrder: [ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE],
        currentPageIndex: 0,
        currentPage: ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE,
      };
    },
    phoneVerificationSuccess: (state) => {
      return { ...state, isPhoneVerified: true };
    },
    sendVerificationCodeSuccess: (state) => {
      return { ...state, wasPhoneSubmitted: true };
    },
    resetPhoneVerification: (state) => {
      return { ...state, wasPhoneSubmitted: false };
    },
    onBiometrySuccess: (
      state,
      action: PayloadAction<OnBiometrySuccessAction>
    ) => {
      return {
        ...state,
        successfulBiometryImageBase64: action.payload.imageBase64,
      };
    },
    onSuccessfulDocumentPhoto: (
      state,
      action: PayloadAction<OnSucessfulDocumentPhotoAction>
    ) => {
      const { imageBase64, documentType, document } = action.payload;
      const photosAlreadyTaken =
        state.successfulDocumentPhotos[documentType.document];
      const updatedSuccessfulDocumentPhotos = {
        ...state.successfulDocumentPhotos,
        [documentType.document]: {
          ...photosAlreadyTaken,
          [documentType.documentViewAngle]: imageBase64,
        },
      };

      let hasTakenAllRequiredDocumentPhotos = false;
      if (
        updatedSuccessfulDocumentPhotos[documentType.document][
          DocumentViewAngle.FRONT
        ] &&
        updatedSuccessfulDocumentPhotos[documentType.document][
          DocumentViewAngle.BACK
        ]
      ) {
        hasTakenAllRequiredDocumentPhotos = true;
      }

      return {
        ...state,
        successfulDocumentPhotos: updatedSuccessfulDocumentPhotos,
        hasTakenAllRequiredDocumentPhotos,
        currentChosenDocument: document,
      };
    },
    submitApplication: (state, action: PayloadAction<string>) => {
      return { ...state, makingRequest: true, referralCode: action.payload };
    },
    submitApplicationLoading: (state) => {
      return { ...state, isEvaluatingApplication: true };
    },
    submitApplicationSuccess: (state) => {
      return {
        ...state,
        makingRequest: false,
        pagesOrder: [ApplicationPages.LOADING_PAGE],
        currentPage: ApplicationPages.LOADING_PAGE,
        currentPageIndex: 0,
      };
    },
    submitApplicationError: (state) => {
      return { ...state, makingRequest: false, isEvaluatingApplication: false };
    },
    endApplicationStatusPooling: (
      state,
      action: PayloadAction<EndApplicationStatusPoolingAction>
    ) => {
      const { status, creditLimit } = action.payload;

      switch (status) {
        case ApplicationStatus.APPROVED:
          FirebaseService.logScreenView(ApplicationPages.APPLICATION_APPROVED_PAGE);
          return {
            ...state,
            isEvaluatingApplication: false,
            pagesOrder: [ApplicationPages.APPLICATION_APPROVED_PAGE],
            currentPage: ApplicationPages.APPLICATION_APPROVED_PAGE,
            currentPageIndex: 0,
            creditLimit,
          };
        case ApplicationStatus.WAITING_MANUAL_APPROVAL:
          FirebaseService.logScreenView(ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE);
          return {
            ...state,
            isEvaluatingApplication: false,
            pagesOrder: [ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE],
            currentPage: ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE,
            currentPageIndex: 0,
          };
        case ApplicationStatus.DENIED:
        case ApplicationStatus.FAILURE:
          FirebaseService.logScreenView(ApplicationPages.DENIED_PAGE);
          return {
            ...state,
            isEvaluatingApplication: false,
            pagesOrder: [ApplicationPages.DENIED_PAGE],
            currentPage: ApplicationPages.DENIED_PAGE,
            currentPageIndex: 0,
          };
      }

      return {
        ...state,
        isEvaluatingApplication: false,
        pagesOrder: [ApplicationPages.LOADING_PAGE],
        currentPage: ApplicationPages.LOADING_PAGE,
        currentPageIndex: 0,
      };
    },
    nextPage: (state) => {
      const newPageIndex = state.currentPageIndex + 1;
      const newPage = state.pagesOrder[newPageIndex];

      FirebaseService.logScreenView(newPage);
      return { ...state, currentPageIndex: newPageIndex, currentPage: newPage };
    },
    previousPage: (state) => {
      const newPageIndex = state.currentPageIndex - 1;
      if (newPageIndex < 0) return;

      const newPage = state.pagesOrder[newPageIndex];
      if (newPage === ApplicationPages.CPF_PAGE) {
        return {
          ...initialState,
          currentPageIndex: newPageIndex,
          currentPage: newPage,
        };
      }

      return { ...state, currentPageIndex: newPageIndex, currentPage: newPage };
    },
    setReferralCode: (state, action: PayloadAction<string>) => {
      return { ...state, referralCode: action.payload };
    },
  },
});

export default applicationReducer;

interface OnFormChangeAction {
  type: string;
  value: any;
}

interface OnBiometrySuccessAction {
  imageBase64: string;
}

interface OnSucessfulDocumentPhotoAction {
  imageBase64: string;
  documentType: DocumentPhotoDescription;
  document: AllowedDocuments;
}

interface EndApplicationStatusPoolingAction {
  status: ApplicationStatus;
  creditLimit?: number;
}
