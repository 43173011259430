import { 
  InputAdornment, 
  TextField, 
  TextFieldProps 
} from '@mui/material';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import React, { ChangeEvent, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const ENTER_KEYCODE = 13;

interface IApplicationInputProps extends React.InputHTMLAttributes<TextFieldProps> {
  Icon?: React.ElementType;
  value?: string;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  isValidated?: boolean;
  isDisabled?: boolean;
  onChange?: (value: ChangeEvent<TextFieldProps>) => void;
  onEnterPressed?: () => void;
  style?: React.CSSProperties;
  Mask?: React.ElementType;
  params?: any; // TODO
  adornmentStyle?: React.CSSProperties;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

// Shake animation for error state
const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

// Styled TextField with animation
const StyledTextField = styled(TextField)<{ isAnimationRunning: boolean }>`
  animation: 1s ${shakeAnimation} ${(props) => (props.isAnimationRunning ? 'infinite' : 'none')};
`;

// Error label styling
const LabelError = styled.p`
  color: #f44336;
  font-family: Roboto;
  margin: 0;
`;

// Loading icon with spin animation
const LoadingIcon = styled(DonutLargeIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
`;

export const ApplicationInput = (props: IApplicationInputProps) => {
  const [isAnimationRunning, setAnimationRunning] = useState<boolean>(false);

  const {
    Icon,
    Mask,
    value,
    label,
    placeholder,
    isLoading,
    isValidated,
    isDisabled,
    params,
    error,
    errorText,
    adornmentStyle,
    required,
    onChange,
    onEnterPressed,
    ...rest
  } = props;

  const handleChange = (event: ChangeEvent<TextFieldProps>) => {
    if (onChange) onChange(event);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<TextFieldProps>) => {
    if (event.keyCode === ENTER_KEYCODE && onEnterPressed) {
      if (error) {
        setAnimationRunning(true);
        setTimeout(() => setAnimationRunning(false), 500);
      }
      onEnterPressed();
    }
  };

  const handleAdornmentClick = () => {
    if (onEnterPressed && !isDisabled) onEnterPressed();
  };

  const inputBorderColor = error 
    ? 'red' 
    : isValidated 
    ? '#11B048' 
    : '#9A9A9A';

  return (
    <>
      <StyledTextField
        {...params}
        {...rest}
        isAnimationRunning={isAnimationRunning}
        disabled={isDisabled}
        required={required}
        onKeyDown={handleOnKeyDown}
        onChange={handleChange}
        value={value}
        label={label}
        InputProps={{
          ...(params && params.InputProps),
          style: adornmentStyle ? adornmentStyle : undefined,
          inputComponent: Mask ? Mask : undefined,
          endAdornment: (
            <InputAdornment style={{ color: inputBorderColor }} position="end">
              {isLoading ? (
                <LoadingIcon />
              ) : Icon ? (
                <Icon onClick={handleAdornmentClick} />
              ) : (
                <></>
              )}
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
      {error && errorText && <LabelError>{errorText}</LabelError>}
    </>
  );
};
