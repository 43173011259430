import { createTheme, ThemeProvider } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import CpfPage from './pages/CpfPage';
import styled from 'styled-components';
import PhoneVerificationPage from './pages/PhoneVerificationPage';
import { ToastContainer } from 'react-toastify';
import { useAppSelector } from './redux/hooks';
import { ApplicationPages } from './redux/reducers/application.reducer';
import LoadingPage from './pages/LoadingPage';
import BorrowerDetailsPage from './pages/BorrowerDetailsPage';
import ZipCodePage from './pages/ZipCodePage';
import BiometryPage from './pages/BiometryPage';
import DocumentsPage from './pages/DocumentsPage';
import WaitingManaualApprovalPage from './pages/WaitingManualApprovalPage';
import OnboardingPage from './pages/OnboardingPage';
import ApplicationDeniedPage from './pages/ApplicationDeniedPage';
import ApplicationAlreadyExistsPage from './pages/ApplicationAlreadyExistsPage';
import FirebaseService from './services/firebase/FirebaseService';
import ApprovedApplicationPage from './pages/ApprovedApplicationPage';

const theme = createTheme({
  palette: {
    primary: {
      main: "#11B048",
    },
  },
});

FirebaseService.init()

function App() {
  const { currentPage } = useAppSelector((state) => ({
    currentPage: state.application.currentPage,
  }));

  const renderCurrentApplicationPage = () => {
    switch (currentPage) {
      case ApplicationPages.CPF_PAGE:
        return <CpfPage />;
      case ApplicationPages.PHONE_PAGE:
        return <PhoneVerificationPage />;
      case ApplicationPages.LOADING_PAGE:
        return <LoadingPage />;
      case ApplicationPages.BORROWER_DETAILS_PAGE:
        return <BorrowerDetailsPage />;
      case ApplicationPages.ZIP_CODE_PAGE:
        return <ZipCodePage />;
      case ApplicationPages.BIOMETRY_PAGE:
        return <BiometryPage />;
      case ApplicationPages.DOCUMENTS_PAGE:
        return <DocumentsPage />;
      case ApplicationPages.WAITING_MANUAL_APPROVAL_PAGE:
        return <WaitingManaualApprovalPage />;
      case ApplicationPages.ONBOARDING:
        return <OnboardingPage />;
      case ApplicationPages.DENIED_PAGE:
        return <ApplicationDeniedPage />;
      case ApplicationPages.APPLICATION_ALREADY_EXISTS_PAGE:
        return <ApplicationAlreadyExistsPage />
      case ApplicationPages.APPLICATION_APPROVED_PAGE:
        return <ApprovedApplicationPage />
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {renderCurrentApplicationPage()}
        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}

export default App;

const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin: 5%;

  max-width: 500px;
  max-height: 1000px;
  align-self: center;

  margin: auto;
`;
