import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, fork, put, race, take, takeLatest } from 'redux-saga/effects'
import { AppToaster } from '../../components/toaster/AppToaster';
import { bffService } from '../../services';
import { ApplicationStatus, GetApplicationStatusDTO } from '../../services/bff/BffService';
import { BffServiceError } from '../../services/bff/BffServiceError';
import applicationReducer from "../reducers/application.reducer";

function* submitApplication(referralCode?: PayloadAction<string>) {
    const response: void | BffServiceError = yield bffService.submitApplication(referralCode?.payload)
    if(response instanceof BffServiceError) {
        yield put(applicationReducer.actions.submitApplicationError())
        AppToaster.showErrorToast('Erro ao submeter cadastro. Tente novamente')
        return
    }
    yield put(applicationReducer.actions.submitApplicationSuccess())
}

function* poolApplicationStatus() {
    while(true) {
        const response: GetApplicationStatusDTO | BffServiceError = yield bffService.getApplicationStatus()

        if(response instanceof BffServiceError || response.status === ApplicationStatus.UNDER_ANALYSIS) {
            yield delay(1000)
            continue
        }

        yield put(applicationReducer.actions.endApplicationStatusPooling({ 
            status: response.status,
            creditLimit: response.creditLimit 
        }))

        yield delay(1000)
    }
}
  

function* poolApplicationStatusWatcher() {
    while (true) {
      yield take(applicationReducer.actions.submitApplicationSuccess)
      yield race([call(poolApplicationStatus), take(applicationReducer.actions.endApplicationStatusPooling)])
    }
}

function* setReferralCode(referralCode: PayloadAction<string>) {
    yield applicationReducer.actions.setReferralCode(referralCode.payload)
}


const applicationSaga = [
    takeLatest(applicationReducer.actions.submitApplication, submitApplication),
    takeLatest(applicationReducer.actions.setReferralCode, setReferralCode),
    fork(poolApplicationStatusWatcher),
]

export default applicationSaga