import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckCircleIcon } from '../assets/icons/check_circle.svg';
import UmeConfigs from '../utils/UmeConfigs';
import { useAppSelector } from '../redux/hooks';

const ApprovedApplicationPage = () => {
  const creditLimit = useAppSelector((state) => state.application.creditLimit);
  const formattedCreditLimit = creditLimit?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 'R$ 0,00';

  return (
    <Container>
      <MainContent>
        <CheckCircleIcon style={{ color: '#6CED7F', width: '48px', height: '48px' }} />
        <StrongText>Cadastro finalizado!</StrongText>
        <Explanation>
          Você já pode comprar com o crédito da Ume informando seu CPF no caixa.
        </Explanation>

        <Divider />

        <CreditAmountSection>
          <CreditLabel>Valor total disponível para comprar</CreditLabel>
          <CreditAmount>{formattedCreditLimit}</CreditAmount>
        </CreditAmountSection>
      </MainContent>

      <Footer>
        <ActionButton onClick={() => window.open(UmeConfigs.APP_DOWNLOAD_URL, '_blank')}>
          Baixar o aplicativo
        </ActionButton>
      </Footer>
    </Container>
  );
};

export default ApprovedApplicationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 32px 24px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: auto 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const StrongText = styled.h1`
  margin-top: 24px;
  margin-bottom: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #454b46;
`;

const Explanation = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #7a7e7a;
  margin-bottom: 32px;
`;

const CreditAmountSection = styled.div`
  width: 100%;
  margin: 32px 0;
  text-align: left;
`;

const CreditLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #596975;
  margin-bottom: 8px;
  text-align: left;
`;

const CreditAmount = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #182026;
  margin: 0;
  text-align: left;
`;

const DownloadAppText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3C4852;
  margin: 0;

  align-self: center;
  width: 80%;
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 16px;
  background: #0D8754;
  border-radius: 50px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background: #0D8754;
  }
`;

const Divider = styled.hr`
  width: 100%;
  height: 2px;
  opacity: 0.8;
  border: none;
  background-color: #C4D0D8;
  margin: 0;
`;
