import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styled from 'styled-components';

interface Props {
  title: string;
  defaultImg: string;
  validImg?: string;

  onTakePhotoRequest: () => void;
}

const DocumentPhotoCard = ({ defaultImg, validImg, title, onTakePhotoRequest }: Props) => {
  const handleTakePhotoRequestClick = () => {
    onTakePhotoRequest();
  };

  if (validImg) {
    return (
      <Container>
        <Title>{title}</Title>
        <ValidDocumentContainer>
          <DocumentImg src={validImg} />

          <DocumentStatusContainer>
            <CheckBoxIcon />
            <ValidText> Foto submetida </ValidText>
          </DocumentStatusContainer>
        </ValidDocumentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      <DocumentContainer>
        <DocumentImg src={defaultImg} />

        <DocumentStatusContainer onClick={handleTakePhotoRequestClick}>
          <CameraAltIcon />
          <TakePhotoText> Tirar foto </TakePhotoText>
        </DocumentStatusContainer>
      </DocumentContainer>
    </Container>
  );
};

export default DocumentPhotoCard;

const ValidDocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 1vh;

  color: green;
`;

const ValidText = styled.span`
  color: green;
  margin-top: 0.5vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Color/Dark/400 */

  color: #454b46;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 1vh;
`;

const DocumentImg = styled.img`
  height: 100px;
  width: auto;
  max-width: 50%;
`;

const DocumentStatusContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;
`;

const TakePhotoText = styled.span`
  text-decoration: underline;
  margin-top: 0.5vh;
  color: blue;
`;
