import devConfig from '../config.dev.json';
import stagingConfig from '../config.staging.json';
import prodConfig from '../config.prod.json';
import { NumberUtils } from '../utils/NumberUtils';

const livenessRandomNumber = NumberUtils.getRandomArbitrary(1, 100);

export enum Environments {
  PRD = 'prd',
  STG = 'stg',
  DEV = 'dev',
}

export interface JsonConfig {
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  unicoSDKConfig: {
    projectNumber: string;
    projectId: string;
    mobileSDKAppId: string;
    hostname: string;
    hostInfo: string;
    hostKey: string;
    modelsPath: string;
  };
  unicoLivenessSDKConfig: {
    projectNumber: string;
    projectId: string;
    mobileSDKAppId: string;
    hostname: string;
    hostInfo: string;
    hostKey: string;
    modelsPath: string;
    resourcesPath: string;
  };
  useLivenessPercentage: number;
}

export interface Config extends JsonConfig {
  shouldUseLiveness: boolean;
}

export const currentEnv = process.env.REACT_APP_NODE_ENV || Environments.DEV;
export default class AppConfig {
  static get googleAnalyticsTag() {
    return 'G-JHT8K96EL7';
  }

  static get isProduction() {
    return currentEnv === Environments.PRD;
  }

  static getConfig(): Config {
    switch (currentEnv) {
      case Environments.DEV:
        return {
          ...devConfig,
          shouldUseLiveness: this.probabilityChecker(
            livenessRandomNumber,
            devConfig.useLivenessPercentage
          ),
        };
      case Environments.STG:
        return {
          ...stagingConfig,
          shouldUseLiveness: this.probabilityChecker(
            livenessRandomNumber,
            stagingConfig.useLivenessPercentage
          ),
        };
      case Environments.PRD:
        return {
          ...prodConfig,
          shouldUseLiveness: this.probabilityChecker(
            livenessRandomNumber,
            prodConfig.useLivenessPercentage
          ),
        };
      default:
        return {
          ...devConfig,
          shouldUseLiveness: this.probabilityChecker(
            livenessRandomNumber,
            devConfig.useLivenessPercentage
          ),
        };
    }
  }

  static probabilityChecker(
    randomNumber: number,
    probabilityChecker: number
  ): boolean {
    if (randomNumber <= probabilityChecker) {
      return true;
    } else {
      return false;
    }
  }
}
