import React, { ChangeEvent, useRef, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import NumberField from '../components/NumberField';
import ApplicationLayout from '../layout/ApplicationLayout';
import { ApplicationInput } from '../components/ApplicationInput';
import ZipCodeSearchService from '../services/zipcode/ZipcodeSearchService';
import { TextField, TextFieldProps } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import applicationReducer, { ApplicationFormAttributes } from '../redux/reducers/application.reducer';
import { ZipCodeSearchError } from '../services/zipcode/ZipcodeSearchError';
import { AppToaster } from '../components/toaster/AppToaster';
import { bffService } from '../services';
import { BffServiceError } from '../services/bff/BffServiceError';

const ZipCodePage = () => {
  const dispatch = useAppDispatch();

  const { zipCode, enrichedZipCode, addressNumber, additionalAddress } = useAppSelector((state) => ({
    zipCode: state.application.zipCode,
    enrichedZipCode: state.application.enrichedZipCode,
    addressNumber: state.application.addressNumber,
    additionalAddress: state.application.additionalAddress,
  }));

  const [formZipCode, setFormZipCode] = useState<string>(zipCode);

  const [isZipCodeValid, setIsZipCodeValid] = useState<boolean>(!!enrichedZipCode);

  const [formAddressNumber, setFormAddressNumber] = useState<string>(addressNumber);
  const [formAdditionalAddress, setFormAdditionalAddress] = useState<string>(additionalAddress);

  const cepRef = useRef<HTMLInputElement>(null);
  const addressNumberRef = useRef<HTMLInputElement>(null);
  const additionalAddressRef = useRef<HTMLInputElement>(null);

  const handleZipCodeChange = (event: NumberFormatValues) => {
    setFormZipCode(event.value);
  };

  const handleZipCodeSubmit = async () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.ZIP_CODE, value: formZipCode }));
    const enrichedZipCode = await ZipCodeSearchService.searchAddressByZipCode(formZipCode);
    if (enrichedZipCode instanceof ZipCodeSearchError) {
      AppToaster.showErrorToast('CEP inválido. Por favor digite um CEP válido para continuar');
      setIsZipCodeValid(false);
      dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.ENRICHED_ZIP_CODE, value: undefined }));
    } else {
      setIsZipCodeValid(true);
      dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.ENRICHED_ZIP_CODE, value: enrichedZipCode }));
    }
  };

  const handleZipCodeEnter = () => {
    handleZipCodeSubmit();
    if (isZipCodeValid) {
      // handleFormSubmit(() => {})
    }
  };

  // --- ADDRESS
  const handleFormAddressNumberChange = (event: ChangeEvent<TextFieldProps>) => {
    setFormAddressNumber(event.target.value as string);
  };
  const handleFormAddressNumberSubmit = () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.ADDRESS_NUMBER, value: formAddressNumber }));
  };

  // --- ADDITIONAL ADDRESS
  const handleFormAdditionalAddressChange = (event: ChangeEvent<TextFieldProps>) => {
    setFormAdditionalAddress(event.target.value as string);
  };
  const handleFormAdditionalAddressSubmit = () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.ADDITIONAL_ADDRESS, value: formAdditionalAddress }));
  };

  const handleFormSubmit = async (defaultAction: Function) => {
    if (!enrichedZipCode) {
      return AppToaster.showErrorToast('Por favor preencha um cep válido!'); 
    }

    const response = await bffService.updateApplicationAddress({
      city: enrichedZipCode?.localidade,
      district: enrichedZipCode?.bairro,
      federativeUnit: enrichedZipCode?.uf,
      streetName: enrichedZipCode?.logradouro,
      streetNumber: formAddressNumber,
      complement: formAdditionalAddress,
      zipCode: formZipCode,
      ibge: enrichedZipCode?.ibge ? Number(enrichedZipCode?.ibge) : undefined,
    });

    if (response instanceof BffServiceError) {
      // TODO(lucas.citolin): differentiate errors
      AppToaster.showErrorToast('Erro ao atualizar os dados. Tente novamente.');
    }

    defaultAction();
  };

  const isNextButtonEnabled = !!enrichedZipCode && formAddressNumber.length >= 1;

  return (
    <ApplicationLayout
      title={'Dados residenciais'}
      subtitle={'Agora, insira abaixo os dados do seu endereço atual.'}
      isNextButtonEnabled={isNextButtonEnabled}
      overrideNextPage={handleFormSubmit}
    >
      <PhoneNumberContainer>
        <NumberField
          inputRef={cepRef}
          value={formZipCode}
          onValueChange={handleZipCodeChange}
          onBlur={handleZipCodeSubmit}
          onEnterPressed={handleZipCodeEnter}
          error={!isZipCodeValid && formZipCode.length ? true : false}
          type='tel'
          label='Qual o seu CEP?'
          format='#####-###'
          mask='_'
          placeholder='00000-000'
          isValidated={isZipCodeValid}
          required={true}
        />

        <UFContainer>
          <TextField id='standard-basic' label='UF' variant='standard' value={enrichedZipCode?.uf ?? ' '} disabled={true} />

          <TextField
            id='standard-basic'
            label='Cidade'
            variant='standard'
            value={enrichedZipCode?.localidade ?? ' '}
            disabled={true}
            style={{ marginLeft: 20 }}
          />
        </UFContainer>

        <TextField id='standard-basic' label='Bairro' variant='standard' value={enrichedZipCode?.bairro ?? ' '} disabled={true} />

        <TextField id='standard-basic' label='Logradouro' variant='standard' value={enrichedZipCode?.logradouro ?? ' '} disabled={true} />

        <ApplicationInput
          id='standard-basic'
          label='Número'
          inputRef={addressNumberRef}
          value={formAddressNumber}
          onChange={handleFormAddressNumberChange}
          onEnterPressed={handleFormAddressNumberSubmit}
          onBlur={handleFormAddressNumberSubmit}
          disabled={!!!enrichedZipCode}
          required={true}
        />

        <ApplicationInput
          id='standard-basic'
          label='Complemento'
          inputRef={additionalAddressRef}
          value={formAdditionalAddress}
          onChange={handleFormAdditionalAddressChange}
          onEnterPressed={handleFormAdditionalAddressSubmit}
          onBlur={handleFormAdditionalAddressSubmit}
          disabled={!!!enrichedZipCode}
        />
      </PhoneNumberContainer>
    </ApplicationLayout>
  );
};

export default ZipCodePage;

const PhoneNumberContainer = styled.div`
  height: 80%;

  display: flex;
  flex-direction: column;

  text-align: justify;

  justify-content: space-evenly;
`;

const UFContainer = styled.div`
  display: flex;
`;
