import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
}

const GoBack = ({ onClick }: Props) => {
  return (
    <GoBackContainer onClick={onClick}>
      <ArrowBackIcon />
      <span>Voltar</span>
    </GoBackContainer>
  );
};

export default GoBack;

const GoBackContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  align-self: flex-start;
`;
