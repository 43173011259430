import React, { ChangeEvent, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { ApplicationInput } from '../components/ApplicationInput';
import { TextFieldProps } from '@mui/material';
import { PersonUtils } from '../utils/PersonUtils';
import { DateMask } from '../components/masks/DateMask';
import { EmailUtils } from '../utils/EmailUtils';
import { ApplicationProfession, applicationProfessionsFilterOptions, APPLICATION_PROFESSIONS } from '../utils/application-professions';
import ApplicationLayout from '../layout/ApplicationLayout';
import styled from 'styled-components';
import applicationReducer, { ApplicationFormAttributes } from '../redux/reducers/application.reducer';
import { bffService } from '../services';
import DateUtils from '../utils/DateUtils';
import { BffServiceError } from '../services/bff/BffServiceError';
import { AppToaster } from '../components/toaster/AppToaster';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';

const BorrowerDetailsPage = () => {
  const dispatch = useAppDispatch();

  const { fullName, birthDate, email, profession } = useAppSelector((state) => ({
    fullName: state.application.fullName,
    profession: state.application.profession,
    birthDate: state.application.birthDate,
    email: state.application.email,
  }));

  const [formName, setFormName] = useState<string | undefined>(fullName);
  const nameRef = useRef<HTMLInputElement>(null);

  const [formEmail, setFormEmail] = useState<string | undefined>(email);
  const emailRef = useRef<HTMLInputElement>(null);

  const [formBirthDate, setFormBirthDate] = useState<string | undefined>(birthDate);
  const birthDateRef = useRef<HTMLInputElement>(null);

  const professionRef = useRef<HTMLInputElement>(null);

  const [isUpdatingBorrowerData, setIsUpdatingBorrowerData] = useState<boolean>(false);

  const handleNameChange = (event: ChangeEvent<TextFieldProps>) => {
    const sanitizedName = PersonUtils.sanitizeName(event.target.value as string);
    setFormName(sanitizedName);
  };

  const handleNameSubmit = () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.FULL_NAME, value: formName }));
  };

  const handleNameEnter = () => {
    handleNameSubmit();
    if (isNameValid) {
      birthDateRef.current?.focus();
    }
  };

  const handleBirthDateChange = (event: ChangeEvent<TextFieldProps>) => {
    const data = event.target.value as string;
    setFormBirthDate(data);
  };

  const handleBirthDateSubmit = () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.BIRTH_DATE, value: formBirthDate }));
  };

  const handleBirthDateEnter = () => {
    handleBirthDateSubmit();
    if (isBirthDateValid) {
      professionRef.current?.focus();
    }
  };

  const handleProfessionOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    chosenProfession: ApplicationProfession | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ApplicationProfession>
  ) => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.PROFESSION, value: chosenProfession }));
    emailRef.current?.focus();
  };

  const handleEmailChange = (event: ChangeEvent<TextFieldProps>) => {
    const data = event.target.value as string;
    setFormEmail(data);
  };

  const handleEmailSubmit = () => {
    dispatch(applicationReducer.actions.onFormChange({ type: ApplicationFormAttributes.EMAIL, value: formEmail }));
  };

  const handleFormSubmit = async (defaultAction: Function) => {
    if (!profession) {
      return;
    }

    setIsUpdatingBorrowerData(true);
    const response = await bffService.updateApplicationData({
      birthDate: DateUtils.formatDateToBackend(formBirthDate),
      email: formEmail,
      name: formName,
      professionId: String(profession.id),
    });
    setIsUpdatingBorrowerData(false);

    dispatch(
      applicationReducer.actions.onMultipleFormChange([
        { type: ApplicationFormAttributes.BIRTH_DATE, value: formBirthDate },
        { type: ApplicationFormAttributes.FULL_NAME, value: formName },
        { type: ApplicationFormAttributes.EMAIL, value: formEmail },
      ]),
    );

    if (response instanceof BffServiceError) {
      // TODO(lucas.citolin): differentiate errors
      AppToaster.showErrorToast('Erro ao atualizar os dados. Tente novamente.');
      return;
    }

    defaultAction();
  };

  const isNameValid = PersonUtils.validateNameWithLastName(formName);
  const { isBirthDateValid, errorText } = PersonUtils.validateBirthDate(formBirthDate);
  const isEmailValid = EmailUtils.validateEmail(formEmail);
  const isProfessionValid = profession ? true : false;
  const isNextButtonEnabled = isNameValid && isEmailValid && isProfessionValid && isBirthDateValid;

  return (
    <ApplicationLayout
      title={'Vamos precisar de alguns dados seus'}
      subtitle={'Essas informações são importantes para realizarmos a sua análise de crédito.'}
      isNextButtonEnabled={isNextButtonEnabled}
      overrideNextPage={handleFormSubmit}
      isNextButtonLoading={isUpdatingBorrowerData}
    >
      <Container>
        <ApplicationInput
          required
          autoFocus={true}
          inputRef={nameRef}
          value={formName}
          // TODO(lucas.citolin) isolate
          error={formName?.length !== 0 && !isNameValid}
          onChange={handleNameChange}
          onBlur={handleNameSubmit}
          onEnterPressed={handleNameEnter}
          label='Qual o seu nome completo?'
          placeholder='Nome Sobrenome'
          isValidated={isNameValid}
        />

        <ApplicationInput 
          required
          inputRef={birthDateRef}
          value={formBirthDate}
          error={formBirthDate?.length !== 0 && !isBirthDateValid}
          errorText={errorText}
          Mask={DateMask}
          type='tel'
          onBlur={handleBirthDateSubmit}
          onChange={handleBirthDateChange}
          onEnterPressed={handleBirthDateEnter}
          label='A sua data de nascimento'
          placeholder='DD/MM/AAAA'
          isValidated={isBirthDateValid}
        />

        <Autocomplete
          options={APPLICATION_PROFESSIONS}
          onChange={handleProfessionOnChange}
          value={profession}
          filterOptions={applicationProfessionsFilterOptions}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params: any) => (
            <ApplicationInput
              required
              params={params}
              inputRef={professionRef}
              label='A sua profissão'
              isValidated={isProfessionValid}
              placeholder='PROFISSÃO'
              adornmentStyle={{ paddingRight: 0 }}
            />
          )}
        />

        <ApplicationInput
          required
          inputRef={emailRef}
          value={formEmail}
          label='E o seu email'
          type='email'
          error={formEmail?.length !== 0 && !isEmailValid}
          placeholder='cliente@gmail.com'
          isValidated={isEmailValid}
          onChange={handleEmailChange}
          onBlur={handleEmailSubmit}
          onEnterPressed={handleEmailSubmit}
        />
      </Container>
    </ApplicationLayout>
  );
};

export default BorrowerDetailsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
`;
