import {
  InputAdornment,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import React, { useState } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

const ENTER_KEYCODE = 13;

// Shake animation keyframes
const shakeAnimation = `
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
`;

const StyledNumberFormat = styled(PatternFormat)<any>`
  animation: 1s ${shakeAnimation} ${(props) => (props.animate ? 'infinite' : 'none')};
`;

// Loading icon with spin animation
const LoadingIcon = styled(DonutLargeIcon)`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  animation: spin 1s linear infinite;
`;

interface INumberField extends PatternFormatProps {
  Icon?: React.ElementType;
  isLoading?: boolean;
  isValidated?: boolean;
  disabled?: boolean;
  onEnterPressed?: () => void;
  error?: boolean;
  helperText?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isAnimating?: boolean;
  adornmentStyle?: React.CSSProperties;
  label: string;
}

const CustomTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return <TextField inputRef={ref} {...props} />;
  }
);

const NumberField: React.FC<INumberField> = (props) => {
  const [isAnimationRunning, setAnimationRunning] = useState<boolean>(false);

  const {
    Icon,
    isLoading,
    error,
    isValidated,
    disabled,
    isAnimating,
    adornmentStyle,
    onEnterPressed,
    helperText,
    ...rest
  } = props;

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER_KEYCODE && onEnterPressed) {
      if (error) {
        setAnimationRunning(true);
        setTimeout(() => setAnimationRunning(false), 500); // Reset animation
      }
      onEnterPressed();
    }
  };

  const handleAdornmentClick = () => {
    if (onEnterPressed && !disabled) {
      onEnterPressed();
    }
  };

  const inputBorderColor = error ? 'red' : isValidated ? '#11B048' : '#9A9A9A';

  return (
    <StyledNumberFormat
      {...rest}
      animate={!!(isAnimationRunning || isAnimating)}
      disabled={disabled}
      customInput={CustomTextField}
      onKeyDown={handleOnKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ color: inputBorderColor, ...adornmentStyle }}
          >
            {isLoading ? (
              <LoadingIcon />
            ) : Icon ? (
              <Icon onClick={handleAdornmentClick} />
            ) : null}
          </InputAdornment>
        ),
      }}
      helperText={helperText}
    />
  );
};

export default NumberField;
