import React, { useEffect, useRef, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import NumberField from '../components/NumberField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ApplicationLayout from '../layout/ApplicationLayout';
import LockIcon from '@mui/icons-material/Lock';
import { bffService } from '../services';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { AppToaster } from '../components/toaster/AppToaster';
import ResendVerificationCodeTimer from '../components/ResendVerificationCodeTimer';
import {
  BffServiceError,
  BffServiceErrors,
} from '../services/bff/BffServiceError';
import applicationReducer, {
  ApplicationFormAttributes,
} from '../redux/reducers/application.reducer';
import TimeoutTimer from '../utils/TimeoutTimer';
import PhoneUtils from '../utils/PhoneUtils';
import WhatsappUtils from '../utils/WhatsappUtils';
import UmeConfigs from '../utils/UmeConfigs';

const CODE_LENGTH = 6;
const DEFAULT_SMS_INTERVAL = 45;

const verificationCodeTimer = new TimeoutTimer();

const PhoneVerificationPage = () => {
  const dispatch = useAppDispatch();
  const { phoneNumber, isPhoneVerified, wasPhoneSubmitted } = useAppSelector(
    (state) => ({
      phoneNumber: state.application.phoneNumber,
      isPhoneVerified: state.application.isPhoneVerified,
      wasPhoneSubmitted: state.application.wasPhoneSubmitted,
    })
  );

  const [formPhoneNumber, setFormPhoneNumber] = useState<string>(phoneNumber);
  const [isSubmittingPhoneNumber, setIsSubmittingPhoneNumber] =
    useState<boolean>(false);
  const [phoneNumberSubmitError, setPhoneNumberSubmitError] = 
    useState<boolean>(false);

  const [secondsToSendSMS, setSecondsToSendSMS] = useState<number>(
    verificationCodeTimer.secondsLeft === undefined
      ? DEFAULT_SMS_INTERVAL
      : verificationCodeTimer.secondsLeft
  );
  const [formVerificationCode, setFormVerificationCode] = useState<string>('');
  const [isVerifyingVerificationCode, setIsVerifyingVerificationCode] =
    useState<boolean>(false);
  const [isResendingVerificationCode, setIsResendingVerificationCode] =
    useState<boolean>(false);

  const [
    isVerificationCodeIncorrectAnimation,
    setVerificationCodeIncorrectAnimation,
  ] = useState<boolean>(false);

  const formPhoneNumberRef = useRef<HTMLInputElement>(null);

  const handlePhoneNumberChange = (values: NumberFormatValues) => {
    setFormPhoneNumber(values.value);
  };

  const handlePhoneNumberSubmit = async () => {
    dispatch(
      applicationReducer.actions.onFormChange({
        type: ApplicationFormAttributes.PHONE_NUMBER,
        value: formPhoneNumber,
      })
    );
    setIsSubmittingPhoneNumber(true);

    const response = await bffService.sendPhoneVerificationCode(
      PhoneUtils.addBRLCountryCode(formPhoneNumber)
    );
    if (response instanceof BffServiceError) {
      setIsSubmittingPhoneNumber(false);
      dispatch(applicationReducer.actions.resetPhoneVerification());

      if (
        response.message ===
        BffServiceErrors.PHONE_ALREADY_ACTIVE_FOR_ANOTHER_BORROWER
      ) {
        AppToaster.showErrorToast(
          'Telefone já pertence a outro cliente. Verifique se o número está correto ou entre em contato com nossa central de atendimento.', 
          4000
        );
        setPhoneNumberSubmitError(true)
      } else {
        AppToaster.showErrorToast(
          'Erro ao enviar o código. Telefone está correto?'
        );
      }
      return;
    }
    AppToaster.showSuccessToast('Código enviado!');
    verificationCodeTimer.startTimer(DEFAULT_SMS_INTERVAL);
    dispatch(applicationReducer.actions.sendVerificationCodeSuccess());
    setIsSubmittingPhoneNumber(false);
  };

  const handleChangePhoneNumberClick = () => {
    setIsSubmittingPhoneNumber(false);
    dispatch(applicationReducer.actions.resetPhoneVerification());
    setFormPhoneNumber('');
    if (formPhoneNumberRef) {
      // Timeout is needed due to bug in component animation
      setTimeout(() => formPhoneNumberRef.current?.focus(), 250);
    }
  };

  // -- VERIFICATION CODE

  // TODO(lucas.citolin): this code runs twice. Make sure it only runs once
  // TODO(lucas.citolin): subscriptions do not die on component death
  useEffect(() => {
    verificationCodeTimer.on(TimeoutTimer.TICK_EVENT, (secondsLeft) => {
      setSecondsToSendSMS(secondsLeft);
    });
  }, []);

  // Side Effect: clear verification code if incorrect
  useEffect(() => {
    if (!isVerifyingVerificationCode && !isPhoneVerified) {
      setFormVerificationCode('');
      setVerificationCodeIncorrectAnimation(true);

      setTimeout(() => {
        setVerificationCodeIncorrectAnimation(false);
      }, 500);
    }
  }, [isVerifyingVerificationCode]);

  const handleVerificationCodeChange = async (event: NumberFormatValues) => {
    const verificationCode = event.value;
    setFormVerificationCode(verificationCode);

    if (verificationCode.length === CODE_LENGTH) {
      setIsVerifyingVerificationCode(true);
      const response = await bffService.verifyPhoneVerificationCode(
        PhoneUtils.addBRLCountryCode(formPhoneNumber),
        verificationCode
      );
      if (response instanceof BffServiceError) {
        AppToaster.showErrorToast('Código de verificação inválido');
        setIsVerifyingVerificationCode(false);
        return;
      }
      dispatch(applicationReducer.actions.phoneVerificationSuccess());
      setIsVerifyingVerificationCode(false);
    }
  };

  const handleResendCode = async () => {
    setIsResendingVerificationCode(true);
    await bffService.sendPhoneVerificationCode(
      PhoneUtils.addBRLCountryCode(formPhoneNumber)
    );
    AppToaster.showSuccessToast('Código enviado!');
    dispatch(applicationReducer.actions.sendVerificationCodeSuccess());
    verificationCodeTimer.startTimer(DEFAULT_SMS_INTERVAL);
    setIsResendingVerificationCode(false);
  };

  // TODO(lucas.citolin): check if phone is valid
  const isEnabledToSubmitPhone = formPhoneNumber.length === 11;
  const isVerificationCodeValid = formVerificationCode.length === 6;

  return (
    <ApplicationLayout
      title={'Valide o seu telefone'}
      subtitle={
        'O seu telefone é o meio de comunicação principal com a UME. Após a validação, poderá utilizá-lo para fazer login no aplicativo e autorizar compras.'
      }
      isNextButtonEnabled={isPhoneVerified}
    >
      <PhoneNumberContainer>
        <NumberField
          autoFocus
          inputRef={formPhoneNumberRef}
          Icon={isPhoneVerified ? CheckCircleIcon : undefined}
          value={formPhoneNumber}
          label='Celular Principal'
          placeholder='(92) 90000-0000'
          format='(##) #####-####'
          type='tel'
          mask='_'
          onValueChange={handlePhoneNumberChange}
          onEnterPressed={handlePhoneNumberSubmit}
          isLoading={isSubmittingPhoneNumber}
          isValidated={isVerificationCodeValid || isPhoneVerified}
          disabled={wasPhoneSubmitted || isPhoneVerified}
          error={false}
          // style={{ marginTop: '20px' }}
        />
        <ConfirmButton
          disabled={
            !isEnabledToSubmitPhone || wasPhoneSubmitted || isPhoneVerified
          }
          onClick={handlePhoneNumberSubmit}
        >
          Enviar código SMS
        </ConfirmButton>

        {phoneNumberSubmitError && 
          <UnderlinedLink href={WhatsappUtils.buildURL(
              WhatsappUtils.EXISTING_PHONE_TRIGGER_MESSAGE, 
              UmeConfigs.CUSTOMER_SERVICE_WHATSAPP
          )} target='_blank'>
            Central de atendimento
          </UnderlinedLink>
        }

      </PhoneNumberContainer>

      <VerificationCodeContainer
        style={{
          visibility:
            wasPhoneSubmitted || isPhoneVerified ? 'visible' : 'hidden',
        }}
      >
        <VerificationCodeText style={{ opacity: isPhoneVerified ? 0.5 : 1 }}>
          Informe o código enviado por SMS para o número acima:
        </VerificationCodeText>
        <NumberField
          isAnimating={isVerificationCodeIncorrectAnimation}
          value={formVerificationCode}
          Icon={isPhoneVerified ? CheckCircleIcon : undefined}
          label='Código de verificação'
          placeholder='000-000'
          format='###-###'
          mask='_'
          type='tel'
          style={{ width: '100%', marginTop: '3vh' }}
          onValueChange={handleVerificationCodeChange}
          isLoading={isVerifyingVerificationCode}
          isValidated={isVerificationCodeValid || isPhoneVerified}
          error={
            (formVerificationCode.length > 0 && !isVerificationCodeValid) ||
            isVerificationCodeIncorrectAnimation
          }
          disabled={
            isPhoneVerified ||
            isSubmittingPhoneNumber ||
            isVerifyingVerificationCode
          }
        />
        <ResendVerificationCodeTimer
          isLoading={isResendingVerificationCode}
          handleChangePhoneNumberClick={handleChangePhoneNumberClick}
          isDisabled={isPhoneVerified}
          handleResendCode={handleResendCode}
          secondsToSendSMS={secondsToSendSMS}
        />
      </VerificationCodeContainer>
    </ApplicationLayout>
  );
};

export default PhoneVerificationPage;

const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;

  text-align: justify;
`;

const ConfirmButton = styled.button`
  margin-top: 10px;
  outline: none;
  transition: 0.5s;

  // BUTTON
  background: ${(props: any) =>
    props && props.disabled ? '#a9e1bc' : '#10A343'};
  border-radius: 10px;
  border: 0px;
  padding: 7px;

  // TEXT
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;

  :focus {
    outline: none;
  }
`;

const UnderlinedLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  font-family: Roboto;
  font-size: 14px;
  color: blue;
`;

const VerificationCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerificationCodeText = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;

  color: #000000;
`;
